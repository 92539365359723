import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import Layout from '../molecules/layout'
import { SEO, Flex, Billboard, H3, Button } from '@renderbus/common/components'

import {
  PageContainer,
  BannerContainer,
  ContentWrapper,
  Detail,
  Section,
  Content,
  Circle,
  Date,
  Line,
} from './recharge-lottery.atom'

import Title1 from '../images/recharge-lottery/title1.png'
import Title2 from '../images/recharge-lottery/title2.png'
import Title3 from '../images/recharge-lottery/title3.png'
import Icon1 from '../images/recharge-lottery/icon1.png'
import Icon2 from '../images/recharge-lottery/icon2.png'
import Icon3 from '../images/recharge-lottery/icon3.png'
import Icon4 from '../images/recharge-lottery/icon4.png'

const Methods = [
  {
    icon: Icon1,
    text: '登录账户',
  },
  {
    icon: Icon2,
    text: '充值',
  },
  {
    icon: Icon3,
    text: '抽奖',
  },
  {
    icon: Icon4,
    text: '联系客服',
  },
]
class RechargeLottery extends PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='瑞云效果图渲染'
          keywords='效果图渲染，瑞云效果图，效果图活动，效果图充多少送多少'
          description='瑞云效果图渲染用户专享，共抗疫情，送福利，最高充多少送多少还可参与抽大奖，100%中奖，幸运“鼠”于你'
        />
        <BannerContainer>
          <Flex direction='column' style={{ height: '100%' }}>
            <img src={Title1} alt='共抗疫情，送福利' />
            <H3>
              效果图用户专享<Line>|</Line>
              <Date>活动时间：2020年2月20日-3月20日</Date>
            </H3>
            <Button
              as='a'
              href='https://task.renderbus.com/sso'
              target='_blank'
              backgroundColor='#fffc00'
              color='#007f44'
            >
              立即领奖
            </Button>
          </Flex>
          <Billboard fluid={data.banner.childImageSharp.fluid} />
        </BannerContainer>
        <PageContainer>
          <ContentWrapper>
            <Section>
              <img src={Title2} alt='参与方式' />
              <Detail>
                {Methods.map(({ icon, text }, index) => (
                  <div key={index}>
                    <Circle>
                      <img src={icon} alt={text} />
                    </Circle>
                    <p>{text}</p>
                  </div>
                ))}
              </Detail>
            </Section>
            <Section>
              <img src={Title3} alt='活动规则' />
              <Content>
                <p>1. 本次活动针对于效果图所有用户，不与其他优惠活动共享；</p>
                <p>
                  2.
                  活动期间充值和抽奖的渲染券仅限在效果图渲染使用，不支持退款或提现；抽取的优惠券码仅限于参与活动账号使用，不能转到其他账号；
                </p>
                <p>
                  3.
                  除渲染券以外的中奖用户，请添加A君微信（rayvision_inc）加好友时请标明“兑奖”，实物奖品将在活动结束后7个工作日内统一安排邮寄。瑞云不对奖品在快递运输途中的意外丢失/损坏以及任何额外产生的费用负责；
                </p>
                <p>4. 本次活动的最终解释权归瑞云科技所有。</p>
              </Content>
            </Section>
          </ContentWrapper>
        </PageContainer>
      </Layout>
    )
  }
}
export default RechargeLottery

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/recharge-lottery/banner.png/" }) {
      ...fluidImage
    }
  }
`
